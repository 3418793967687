import React from "react";
import "./styles.css";

import cta from "../../assets/call_contrate.png";
import card1 from "../../assets/card_documentos.png";
import card2 from "../../assets/card_seguro_viagem.png";
import card3 from "../../assets/card_passagens.png";
import card4 from "../../assets/card_cruzeiro.png";
import card5 from "../../assets/card_aluguel_carro.png";
import card6 from "../../assets/card_hospedagem.png";
import catamara from "../../assets/catamara.png";
import cta_wpp from "../../assets/call_whats.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h4 className="title">DEMAIS SERVIÇOS</h4>

          <p className="subtitle">
            Além de viagens, também oferecemos diversos outros serviços:
          </p>

          <div className="gallery">
            <div className="card">
              <figure>
                <img src={card1} alt="" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="" />
                </a>
              </figure>
            </div>

            <div className="card">
              <figure>
                <img src={card2} alt="" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="" />
                </a>
              </figure>
            </div>

            <div className="card">
              <figure>
                <img src={card3} alt="" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="" />
                </a>
              </figure>
            </div>

            <div className="card">
              <figure>
                <img src={card4} alt="" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="" />
                </a>
              </figure>
            </div>

            <div className="card">
              <figure>
                <img src={card5} alt="" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="" />
                </a>
              </figure>
            </div>

            <div className="card">
              <figure>
                <img src={card6} alt="" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </article>

      <article className="content2">
        <div className="left">
          <div className="container">
            <h2 className="title">SOBRE A CATAMARÃ TURISMO</h2>

            <p>
              Não somos apenas uma agência de viagens, mas uma nova concepção de
              assessoria em viagens, que está há 20 anos no mercado de turismo e
              viagens. Sempre desenvolvemos soluções e ferramentas para atender
              as necessidades de nossos clientes.
            </p>

            <p>
              <b>FALE CONOSCO:</b>
            </p>

            <figure>
              <a
                href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                target="_blank"
                rel="noreferrer"
              >
                <img src={cta_wpp} alt="Chamada para ação de Whatsapp" />
              </a>
            </figure>
          </div>
        </div>

        <figure className="right">
          <img src={catamara} alt="Catamarã Turismo" />
        </figure>
      </article>
    </section>
  );
};

export default Content;
