import { useEffect, useState } from "react";
import "./styles.css";

import cta from "../../assets/WHATS.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("FAÇA UMA COTAÇÃO AGORA:");

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="cta">
      <p>
        <b>{text}</b>
      </p>

      <figure id="ctaWpp">
        <a
          href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
          target="_blank"
          rel="noreferrer"
        >
          <img src={cta} alt="Chamada de ação para Whatsapp" />
        </a>
      </figure>
    </div>
  );
};

export default CtaWpp;
