import React from "react";
import "./styles.css";

import SwiperCore, {
  Pagination,
  Navigation,
  EffectCoverflow,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CTAWpp from "../../components/CTAWpp";

import AVTR1 from "../../assets/banner1.png";
import AVTR2 from "../../assets/banner2.png";
import AVTR3 from "../../assets/banner3.png";
import AVTR4 from "../../assets/banner4.png";
import AVTR1Mobile from "../../assets/mobile/banner1.png";
import AVTR2Mobile from "../../assets/mobile/banner2.png";
import AVTR3Mobile from "../../assets/mobile/banner3.png";
import AVTR4Mobile from "../../assets/mobile/banner4.png";

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

const data = [
  {
    id: 1,
    image: AVTR1,
    imageMobile: AVTR1Mobile,
  },
  {
    id: 2,
    image: AVTR2,
    imageMobile: AVTR2Mobile,
  },
  {
    id: 3,
    image: AVTR3,
    imageMobile: AVTR3Mobile,
  },
  {
    id: 4,
    image: AVTR4,
    imageMobile: AVTR4Mobile,
  },
];

const Header = () => {
  return (
    <header id="header">
      <article className="header1">
        <Swiper
          className="carousel"
          effect={"coverflow"}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
        >
          {data.map(({ id, image, imageMobile }) => {
            return (
              <SwiperSlide key={id} className="banner">
                <img
                  src={
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? imageMobile
                      : image
                  }
                  alt={`Exemplo ${id} de Viagem`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div id="banner_cta">
          <CTAWpp />
        </div>

        <div className="mobile">
          <CTAWpp />
        </div>
      </article>

      <article className="header2">
        <div className="container">
          <h2 className="title">MUITO MAIS QUE VIAGENS incríveis! </h2>

          <p>
            Desfrute das melhores viagens, nacionais e internacionais, com a
            experiência que só a Catamarã Turismo pode oferecer. Conheça nossos
            pacotes de viagem que te levarão aos mais belos lugares dentro e
            fora do Brasil!
          </p>
        </div>
      </article>
    </header>
  );
};

export default Header;
