import React from "react";
import "./styles.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CTAWpp from "../../components/CTAWpp";

import AVTR1 from "../../assets/card_nordeste_1.png";
import AVTR2 from "../../assets/card_nordeste_2.png";
import AVTR3 from "../../assets/card_nordeste_3.png";
import AVTR4 from "../../assets/card_nordeste_4.png";
import AVTR1Mobile from "../../assets/mobile/card_nordeste_1.png";
import AVTR2Mobile from "../../assets/mobile/card_nordeste_2.png";
import AVTR3Mobile from "../../assets/mobile/card_nordeste_3.png";
import AVTR4Mobile from "../../assets/mobile/card_nordeste_4.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: AVTR1,
    imageMobile: AVTR1Mobile,
    title: "<b>Aproveite nosso pacote Rota do Velho Chico 2024.</b>",
    text: "Desfrute das belezas naturais e pontos turísticos de Aracaju/Sergipe e Piranhas/Alagoas em uma viagem de 6 dias. Visite praias incríveis, o Rio São Francisco e os Cânions do Xingó.",
  },
  {
    id: 2,
    image: AVTR2,
    imageMobile: AVTR2Mobile,
    title: "<b>CHEGOU A HORA DE VOCÊ PROGRAMAR SUAS FÉRIAS DE JANEIRO!</b>",
    text: "Muita gente já garantiu lugar para uma viagem inesquecível a bordo do espetacular Seaview para o próximo Cruzeiro do Cidade Viva! Agora só falta você! <br /><br /> <b>Nosso roteiro:</b> <br /><br /> SANTOS - ILHA GRANDE (ANGRA) - BÚZIOS - NAVEGAÇÃO - SANTOS. ",
  },
  {
    id: 3,
    image: AVTR3,
    imageMobile: AVTR3Mobile,
    title:
      "<b>Venha com a Catamarã para mais uma viagem incrível ao exterior! Conheça 4 das mais belas cidades turcas em nosso Pacote Especial Turquia!</b> <br/>",
    text: "Venha conosco conhecer Istambul, Passear de Balão na Capadócia, Visitar a cidade de Bursa e conhecer o incrível Castelo de Algodão em Pamukale <br /> <br /> <span style='font-size: 12px;'>Prezado Cliente: Preço por pessoa em base dupla com saída de São Paulo em Classe Econômica. Preços e datas de saída, condições de pagamento sujeito a reajuste e alteração sem aviso prévio. Fotos ilustrativas. Taxas inclusas nos valores. Somente a local que será pago no hotel diretamente pelo passageiro. Os Passeios descritos como Opcional no roteiro estão descritos à parte em tabela mencionada no descritivo. Os pacotes devem ser calculados no câmbio do dia do fechamento. Valor calculados no cambio de 09/08/2024 à R$ 5,79. Parcelamento: 20% de entrada e o saldo em 9X no cartão sem juros. A sequência das cidades e locais a serem visitados durante o programa estarão sujeitos a alteração devido as circunstâncias. </span>",
  },
  {
    id: 4,
    image: AVTR4,
    imageMobile: AVTR4Mobile,
    title:
      "<b>A Catamarã providencia toda a sua documentação de viagem, passaporte, vistos e outros documentos necessários em todos os países do mundo.</b>",
    text: "Fale nossos agentes de viagens, estamos preparados para realizar os seus sonhos ao redor do mundo!",
  },
];

const About = () => {
  return (
    <section id="about">
      <div className="container">
        {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ? (
          <div className="mobile">
            {data.map(({ id, imageMobile, title, text }) => {
              return (
                <div className="item" key={id}>
                  <figure className="top">
                    <img src={imageMobile} alt={`Exemplo ${id} de Viagem`} />
                  </figure>

                  <p
                    className="item_title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></p>

                  <p
                    className="item_text"
                    dangerouslySetInnerHTML={{ __html: text }}
                  ></p>

                  <CTAWpp text={"FAÇA UMA COTAÇÃO:"} />
                </div>
              );
            })}
          </div>
        ) : (
          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={2}
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image, title, text }) => {
              return (
                <SwiperSlide key={id} className="banner">
                  <div className="item">
                    <figure className="top">
                      <img src={image} alt={`Exemplo ${id} de Viagem`} />
                    </figure>

                    <p
                      className="item_title"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></p>

                    <p
                      className="item_text"
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></p>

                    <CTAWpp text={"FAÇA UMA COTAÇÃO:"} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default About;
