import React from "react";
import "./styles.css";

import logo from "../../assets/logo_topo.png";
import phone from "../../assets/fone.png";

const Menu = () => {
  return (
    <nav id="menu">
      <div className="container">
        <figure className="left">
          <img src={logo} alt="Logo" />
        </figure>

        <div className="right">
          <p>LIGUE AGORA</p>
          <img src={phone} alt="Ícone do Whatsapp" />
          <p>(11) 4699-1034</p>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
