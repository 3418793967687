import React from "react";
import "./styles.css";

import logo from "../../assets/logo_rodape.png";
import cta from "../../assets/call_fone.png";
import footer from "../../assets/footer.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <div className="left">
            <figure>
              <img src={logo} alt="Logo" />
            </figure>

            <div className="side">
              <p className="text">
                <b>Mogi das Cruzes – Mogilar</b>
              </p>
              <p className="text">
                Rua José Meloni, 351 - Loja 12 - Mogi das Cruzes / SP – CEP
                08773-120
              </p>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511992467030?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={cta} alt="Chamada para ação de Whatsapp" />
                </a>
              </figure>
            </div>
          </div>

          <figure className="right">
            <img src={footer} alt="20 Anos" />
          </figure>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Catamarã Turismo - 2023 - Todos os direitos reservados
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
